<template>
  <el-dialog
    width="50%"
    title="意见反馈"
    :visible="modelShow"
    append-to-body
    :close-on-click-modal="false"
    :before-close="closeModel"
    top="10vh"
  >
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-width="120px"
      label-position="right"
    >
      <div>
        <el-form-item prop="detail" label="问题描述：">
          <el-input
            type="textarea"
            :resize="'none'"
            v-model="formData.detail"
            placeholder="请输入问题描述"
          ></el-input>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="图片：">
          <imageUpload :limit="1" v-model="formData.imgUrl" />
        </el-form-item>
      </div>
    </el-form>
    <div class="btnBox">
      <el-button @click="closeModel">关闭</el-button>
      <el-button type="primary" @click="onSubmit">提交</el-button>
    </div>
  </el-dialog>
</template>
<script>
import imageUpload from "@/components/imageUpload";
import { CmsFeedback } from "@/api/serviechall";

export default {
  components: { imageUpload },
  props: {
    modelShow: {
      typeof: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        detail: "",
        imgUrl: "",
      },
      formRules: {
        detail: [
          { required: true, message: "请输入描述问题", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    closeModel() {
      this.$emit("closeBackModel", false);
    },
    onSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          CmsFeedback(this.formData).then((res) => {
            const { code, msg } = res;
            if (code == 200) {
              this.$message.success("提交成功");
              this.$emit("closeBackModel", false);
            } else {
              this.$message.error(msg || "提交失败");
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__header {
  background-color: #f5f5f5;
}
/deep/ .el-textarea__inner {
  height: 150px;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>