<template>
  <el-dialog
    width="50%"
    title="在线客服"
    :visible.sync="innerVisible"
    append-to-body
    :close-on-click-modal="false"
    :before-close="closeModel"
    top="5vh"
  >
    <div class="chatContent">
      <div id="content" ref="rightBody">
        <div v-for="item in list" :key="item.id">
          <div class="chat-model" v-if="item.isSelf">
            <div>
              <van-image
                width="45px"
                height="45px"
                fit="fill"
                round
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
            </div>
            <div class="chat-content chat-content-l">
              {{ item.content }}
            </div>
          </div>
          <div class="chat-model" style="justify-content: flex-end" v-else>
            <div class="chat-content chat-content-r">
              {{ item.content }}
            </div>
            <div>
              <van-image
                width="45px"
                height="45px"
                fit="fill"
                round
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="bottom">
        <el-input type="textarea" :resize="'none'" v-model="textContent" />
        <div class="btnBox">
          <el-button @click="closeModel">关闭</el-button>
          <el-button :disabled="isText" type="primary" @click="onSend">发送</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getToken } from "@/utils/auth";

export default {
  name: "HelloWorld",
  props: {
    innerVisible: {
      typeof: Boolean,
      default: true,
    },
  },
  data() {
    return {
      path: "ws://hnjinjun.com:3000/myHandler/", // socket 地址
      socket: "",
      textContent: "",
      isText: true,
      dataObj: {
        code: 9002,
        msg: "",
        recId: "",
        itemId: 1,
        serviceNickName: "", //客服人员name
        clientNickName: "", //如果登录就从用户信息获取，未登录就是空
      },
      list: [],
      time: "", // 定时器
    };
  },
  created() {
    this.init();
    if (getToken()) {
      this.dataObj.recId = this.$store.state.user.userId;
      this.dataObj.clientNickName = this.$store.state.user.name;
    }
  },
  methods: {
    closeModel() {
      this.$emit("closeModel", false);
    },
    onSend() {
      if (this.socket.readyState != 1) {
        this.$toast("连接失败请重新进入");
        return;
      }
      if (!this.textContent) {
        this.$toast("请输入内容");
        return;
      }
      var data = {
        avator: "https://img01.yzcdn.cn/vant/cat.jpeg",
        content: this.textContent,
        isSelf: false,
      };
      this.list.push(data);
      this.send();
      this.textContent = "";
      this.$nextTick(() => {
        this.$refs.rightBody.scrollTop = this.$refs.rightBody.scrollHeight;
      });
    },
    init: function () {
      // 0        CONNECTING        连接尚未建立
      // 1        OPEN            WebSocket的链接已经建立
      // 2        CLOSING            连接正在关闭
      // 3        CLOSED            连接已经关闭或不可用
      if (typeof WebSocket === "undefined") {
        this.$toast("您的浏览器不支持socket");
      } else {
        let id = Math.random(); // 随机数
        // 实例化socket
        // var webSocket = new WebSocket(path + '?token=' + id + '&admin=1&itemId=1');

        this.socket = new WebSocket(this.path + "?token=" + id + "&itemId=1");
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
        this.onHeartbeat(); // 心跳防止断开连接
        //断开连接
        this.socket.onclose = this.close;
      }
    },
    open: function () {
      this.$message.success("连接成功");
    },
    error: function () {
      this.$message.error("连接失败");
    },
    getMessage: function (res) {
      let t = JSON.parse(res.data);
      var data = {
        avator: "https://img01.yzcdn.cn/vant/cat.jpeg",
        content: t.msg,
        isSelf: true,
      };
      if (t.code == 9002) {
        this.list.push(data);
      }
      this.dataObj.serviceNickName = t.serviceNickName;
      this.dataObj.recId = t.recId;
      this.$nextTick(() => {
        this.$refs.rightBody.scrollTop = this.$refs.rightBody.scrollHeight;
      });
    },
    send: function () {
      if (this.socket) {
        this.dataObj.code = 9002;
        this.dataObj.itemId = 1;
        this.dataObj.msg = this.textContent;
        this.socket.send(JSON.stringify(this.dataObj));
      }
    },
    close: function () {
      console.log("socket已经关闭");
    },
    onHeartbeat() {
      var time = setInterval(() => {
        this.dataObj.code = 9003;
        this.dataObj.msg = "客户端心跳";
        this.socket.send(JSON.stringify(this.dataObj));
      }, 5000);
      this.time = time;
    },
  },
  beforeDestroy() {
    // 销毁监听
    clearInterval(this.time);
    this.close();
  },
  watch: {
    textContent(newVal) {
      this.isText = newVal ? false : true;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  background-color: #36bf4b;
}
/deep/ .el-dialog__title {
  color: #fff !important;
}
/deep/ .el-dialog__close {
  font-size: 18px;
  color: #fff;
  font-weight: bolder;
}
/deep/ .el-dialog__body {
  padding: 0;
}
.chatContent {
  width: 100%;
  height: 600px;
  overflow: hidden;
  #content {
    overflow: auto;
    height: 400px;
  }
  #bottom {
    width: 100%;
    height: 200px;
    border-top: 1px solid #ddd;
    padding-bottom: 10px;
    /deep/ .el-textarea__inner {
      /* height: 32px; */
      /* line-height: 32px; */
      width: 100%;
      height: 150px;
      border: none;
      word-break: break-all;
      overflow: auto;
    }
    .btnBox {
      margin-top: 8px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      box-sizing: border-box;
    }
  }
}

.chat-model {
  display: flex;
  flex-direction: row;
  margin: 10px 10px;
  margin-top: 30px;
  align-items: center;
}

.chat-content {
  position: relative;
  max-width: 67%;
  word-break: break-all;
  word-wrap: break-word;
  top: 18px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
}

.chat-content-r {
  right: 10px;
}

.chat-content-l {
  left: 10px;
}
</style>
