<template>
  <div class="Container">
    <div class="bgColor">
      <div class="bgContent">
        <div class="bgText">
          <div class="bgArea el-icon-map-location">
            <span @click="showCity" style="margin-left: 10px">{{
              cityName
            }}</span>
            <div v-show="cityShow" class="city-picke">
              <div class="city-Header">
                <!-- <span>湖南省</span> -->
                <div
                  @click="choiceCity"
                  :class="headerActive == 0 ? 'ch-text1' : ''"
                >
                  城市
                </div>
                <div
                  @click="choiceArea"
                  :class="headerActive == 1 ? 'ch-text1' : ''"
                >
                  区/县名
                </div>
              </div>
              <div v-show="headerActive == 0" class="city-Body">
                <span
                  :class="formData.cityCode == item.code ? 'activeCity' : ''"
                  @click="clickCity(item)"
                  v-for="item in cityList"
                  :key="item.code"
                  >{{ item.name }}</span
                >
              </div>
              <div v-show="headerActive == 1" class="city-Body">
                <span
                  @click="clickArea(item)"
                  :class="formData.areaCode == item.code ? 'activeCity' : ''"
                  v-for="item in areaList"
                  :key="item.code"
                  >{{ item.name }}</span
                >
              </div>
            </div>
          </div>
          <div v-if="!isLoginFlag" class="linkBox">
            <span @click="toRegister">注册&nbsp; | &nbsp;</span>
            <span @click="toLogin">登录</span>
          </div>
          <div v-if="isLoginFlag" class="userInfo">
            <div class="userI-text1">{{ `欢迎您,${username}` }}</div>
            <div @click="goSystem" class="userI-text2">管理平台</div>
            <div @click="loginOut" class="userI-text2">退出登录</div>
          </div>
        </div>
        <div class="bgLogo">社会化服务平台</div>
      </div>
    </div>
    <div class="tabContent">
      <div class="listContent">
        <div class="listBox">
          <span
            :class="changeIndex === index ? 'is-active' : ''"
            @click="handleLink(item, index)"
            v-for="(item, index) in array"
            :key="item.path"
            >{{ item.name }}</span
          >
        </div>
        <div class="bgSearch">
          <el-input v-model="keyWord" type="text" placeholder="请输入关键字">
            <template slot="prefix">
              <div class="slotCopes">
                <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    {{ str }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-show="index !== 0 && index !== 6"
                      :command="index"
                      @click="changeStr(index)"
                      v-for="(item, index) in array"
                      :key="item.path"
                      >{{ item.name }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-input>
          <div class="btnScope">
            <el-button
              class="searchBtn1"
              @click="searchFnc"
              icon="el-icon-search"
              >搜索</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="appMain">
      <router-view />
    </div>
    <div class="footeContent">
      <div class="fc-message">
        <div class="fc-imgContent">
          <div style="margin-right: 20px" class="img-box">
            <img src="../../assets/image/ewm1.png" alt="" />
            <span>平台视频号</span>
          </div>
          <div class="img-box">
            <img src="../../assets/image/ewm2.jpg" alt="" />
            <span>平台公众号</span>
          </div>
        </div>
        <div class="fc-textContent">
          <div class="fc-title">帮助中心</div>
          <div @click="openFeedBack" class="fc-text">意见反馈</div>
          <!-- <div class="fc-text">文件下载</div>
          <div class="fc-text">常见问题</div> -->
        </div>
        <div class="fc-textContent">
          <div class="fc-title">平台规则</div>
          <div @click="goFootLink('/portal/Disclaimers', true)" class="fc-text">
            负责声明
          </div>
          <!-- <div class="fc-text">接口规范</div> -->
        </div>
        <div class="fc-textContent">
          <div class="fc-title">政府服务</div>
          <div
            @click="goFootLink('http://hnjinjun.com/HunanUAVDigital/')"
            class="fc-text"
          >
            名录库
          </div>
          <!-- <div class="fc-text">项目管理</div> -->
        </div>
        <div class="fc-textContent">
          <div class="fc-title">关于我们</div>
          <div class="fc-text">平台简介</div>
          <div class="fc-text">工作日：9:00-18:00</div>
          <!-- <div class="fc-text">010-51508639</div> -->
          <!-- <div class="fc-text">平台邮箱：jingfei@nerctita.org.cn</div> -->
        </div>
      </div>
      <div class="fc-bottom">
        <div class="fcb-content">
          <!-- <div>京ICP备09007982号-17</div> -->
          <!-- <div>隶属：湖南省北斗+无人机数字农业社会化服务平台</div> -->
          <!-- <div>技术支持：国家农业信息化工程技术研究中心</div> -->
          <!-- <div>运营企业：湖南精飞智能科技有限公司</div> -->
          <div
            v-for="item in linkList"
            :key="item.id"
            @click="goFriendLink(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <chat
      v-if="chatModelShow"
      @closeModel="closeChatModel"
      :innerVisible="chatModelShow"
    />
    <FeedBack
      v-if="feedBackModelShow"
      @closeBackModel="closeBackModel"
      :modelShow="feedBackModelShow"
    />
    <div @click="openChat" class="onlineServicc">在线客服</div>
  </div>
</template>
<script>
import { getPlace, getLinkList } from "@/api/serviechall";
import { getToken } from "@/utils/auth";
import chat from "../imclient";
import FeedBack from "./Feedback.vue";

export default {
  components: { chat, FeedBack },
  data() {
    return {
      area: "全国",
      keyWord: "",
      array: [
        { name: "首页", path: "/portal/home", id: 0 },
        {
          name: "新闻资讯",
          path: "/portal/agricultural",
          childernPath: "/portal/agriculturalDeatil",
          id: 1,
        },
        {
          name: "服务大厅",
          path: "/portal/serviceHall",
          childernPath: "/portal/serviceHallDetail",
          id: 2,
        },
        {
          name: "需求大厅",
          path: "/portal/demandHall",
          childernPath: "/portal/demandHallDetail",
          id: 3,
        },
        {
          name: "组织名录",
          path: "/portal/organization",
          childernPath: "/portal/organizationDetail",
          id: 4,
        },
        {
          name: "在线科普",
          path: "/portal/sciencePopularization",
          childernPath: "/portal/sciencePopularizationDetail",
          id: 5,
        },
      ],
      feedBackModelShow: false,
      linkList: [],
      formData: {
        provinceCode: 43,
        cityCode: "",
        areaCode: "",
      },
      cityShow: false,
      cityList: [],
      areaList: [],
      changeIndex: 0,
      cityName: "湖南省",
      areaStr: "",
      headerActive: 0,
      cityCode: "",
      areaCode: "",
      isLoginFlag: false,
      username: "",
      currentObj: {},
      str: "",
      chatModelShow: false,
    };
  },
  methods: {
    closeChatModel(val) {
      this.chatModelShow = val;
    },
    closeBackModel(val) {
      this.feedBackModelShow = val;
    },
    handleLink(item, index) {
      if (item.path == this.$route.path) {
        return;
      }
      this.$router.push(item.path);
    },
    handleCommand(index) {
      this.str = this.array[index].name;
      this.currentObj = this.array[index];
    },
    searchFnc() {
      let query = { id: this.currentObj.id, keyWord: this.keyWord };
      if (this.currentObj.path != this.$route.path) {
        this.$router.push(this.currentObj.path);
        this.$store.dispatch("changeDate", query);
      } else {
        this.$store.dispatch("changeDate", query);
      }
    },
    activIndex() {
      let path = this.$route.path.split("?")[0];
      let flag = false;
      let currentObj = {};
      this.array.map((item) => {
        if (item.path == path || item.childernPath == path) {
          currentObj = item;
          flag = true;
          return;
        }
      });
      let id = currentObj.id;
      this.currentObj = flag ? currentObj : this.array[0];
      this.changeIndex = id;
      if (id == 0 || !flag) {
        this.str = "新闻资讯";
      } else {
        this.str = this.currentObj.name;
      }
    },
    getPlace() {
      const { provinceCode, cityCode, areaCode } = this.formData;
      let query = { areaCode, provinceCode, cityCode, streetCode: "" };
      getPlace(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.cityList = data.cityList;
          this.areaList = data.areaList ? data.areaList : [];
        }
      });
    },
    showCity() {
      this.cityShow = true;
    },
    choiceCity() {
      this.headerActive = 0;
    },
    choiceArea() {
      this.headerActive = 1;
    },
    clickCity(item) {
      this.formData.cityCode = item.code;
      this.getPlace();
      this.headerActive = 1;
      this.formData.areaCode = "";
      this.areaStr = "";
      this.areaStr = "湖南省/" + item.name;
    },
    clickArea(item) {
      this.formData.areaCode = item.code;
      this.cityName = this.areaStr + "/" + item.name;
      this.cityShow = false;
    },
    toRegister() {
      this.$router.push("/portal/register");
    },
    toLogin() {
      this.$router.push("/portal/login");
    },
    async isLogin() {
      if (getToken()) {
        this.isLoginFlag = true;
        await this.$store.dispatch("getInfo");
        this.username = this.$store.state.user.name;
      }
    },
    loginOut() {
      this.$confirm("您确认要退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("logOut");
          this.isLoginFlag = false;
        })
        .catch(() => {});
    },
    goSystem() {
      if (!getToken()) {
        this.$confirm("您当前还未登录要跳转到登录界面吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/portal/login");
          })
          .catch(() => {});
      } else {
        window.open("https://hnjinjun.com/", "_blank");
      }
    },
    openChat() {
      this.chatModelShow = true;
      return;
      if (!getToken()) {
        this.$confirm("您当前还未登录要跳转到登录界面吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/portal/login");
          })
          .catch(() => {});
      } else {
        this.chatModelShow = true;
      }
    },
    openFeedBack() {
      if (!getToken()) {
        this.$confirm("您当前还未登录要跳转到登录界面吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/portal/login");
          })
          .catch(() => {});
      } else {
        this.feedBackModelShow = true;
      }
    },
    getLinkList() {
      getLinkList().then((res) => {
        const { code, rows } = res;
        if (code === 200) {
          this.linkList = rows;
        }
      });
    },
    goFriendLink(item) {
      if (item.redirectUrl) {
        window.open(item.redirectUrl, "_blank");
      }
    },
    goFootLink(linkUrl, isRouter) {
      if (isRouter) {
        let routeUrl = this.$router.resolve({
          path: linkUrl,
        });
        window.open(routeUrl.href, "_blank");
      } else {
        window.open(linkUrl, "_blank");
      }
    },
  },
  created() {
    this.getPlace();
    this.isLogin();
    this.activIndex();
    this.getLinkList();
  },
  computed: {
    watchData() {
      return this.$store.state.user.token;
    },
  },
  watch: {
    $route() {
      this.activIndex();
      this.keyWord = "";
    },
    watchData(newVal) {
      if (!newVal) {
        this.isLoginFlag = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Container {
  height: 100%;
  .bgColor {
    height: 225px;
    background: url(../../assets/image/logo.jpg) no-repeat center center;
    background-size: cover;
    .bgContent {
      position: relative;
      width: 1396px;
      margin: 0 auto;
      .bgText {
        color: #006633;
        opacity: 0.8;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .linkBox {
        text-align: right;
        font-size: 14px;
        color: #17a763;
        line-height: 38px;
        text-decoration: none !important;
        span {
          text-decoration: none !important;
          color: #17a763;
          cursor: pointer;
        }
      }
      .userInfo {
        display: flex;
        .userI-text1 {
          font-size: 14px;
          color: #999999;
          margin-right: 10px;
        }
        .userI-text2 {
          font-size: 14px;
          color: #17a763;
          cursor: pointer;
          margin-right: 10px;
        }
      }
      .bgLogo {
        left: 22%;
        top: 75%;
        // position: absolute;
        // margin: auto;
        // padding-top: 50px;
        // background: url(../../assets/image/logo1.png) no-repeat center center;
        // background-size: cover;
        text-align: center;
        line-height: 145px;
        font-size: 50px;
        letter-spacing: 10px;
        font-weight: 650;
        color: #17a763;
        width: 100%;
        height: 120px;
        font-family: '黑体';
      }
      .bgArea {
        margin: 26px 0px;
        height: 41px;
        margin-left: 40px;
        font-size: 16px;
        float: left;
        line-height: 41px;
        cursor: pointer;
        position: relative;
        .city-picke {
          width: 320px;
          position: absolute;
          left: 0;
          top: 40px;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          z-index: 99;
          .city-Header {
            border-bottom: 1px solid #ccc;
            background: #e0e0e0;
            font-size: 13px;
            display: flex;
            div {
              padding: 0 10px;
              border: 1px solid #ccc;
              border-bottom: none;
            }
            .ch-text1 {
              background-color: #fff;
              color: #17a763;
              border: none;
            }
          }
          .city-Body {
            width: 100%;
            background-color: #fff;
            display: flex;
            flex-wrap: wrap;
            min-height: 20px;
            box-sizing: border-box;
            span {
              font-size: 12px;
              color: #333;
              padding: 0 10px;
              margin-bottom: 6px;
              z-index: 120;
              cursor: pointer;
            }
            span:hover {
              color: #17a763;
            }
            .activeCity {
              background-color: #17a763 !important;
              color: #fff !important;
            }
          }
        }
      }
    }
  }
  .tabContent {
    width: 100%;
    background-color: #17a763;
    .listContent {
      width: 1396px;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      .listBox {
        font-size: 20px;
        color: #fff;
        line-height: 50px;
        display: flex;
        span {
          padding: 0 30px;
          margin-right: 10px;
          height: 48px;
          display: block;
          cursor: pointer;
        }
        span:hover {
          background-color: #007640;
          color: #fff;
        }
        .is-active {
          background-color: #007640;
          color: #fff;
        }
      }
      .bgSearch {
        width: 379px;
        height: 40px;
        display: flex;
        /deep/ .el-input__inner {
          outline: none;
          font-size: 14px;
          color: #999999;
          line-height: 40px;
          float: left;
          width: 300px !important;
          padding-left: 110px;
          background: none;
          height: 40px !important;
          border-radius: 62px 0 0 62px;
          background-color: #fff;
          z-index: 99;
          border-right: none;
        }
        .slotCopes {
          text-align: center;
          font-size: 14px;
          margin-top: 10px;
          margin-left: 26px;
        }
        .searchBtn1 {
          height: 40px;
          border-radius: 0 62px 62px 0;
          background-color: #17a763;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
  .appMain {
    width: 100%;
    margin-bottom: 20px;
  }
  .footeContent {
    padding-top: 23px;
    height: 147px;
    background-color: #d5d5d5;
    width: 100%;
    padding-bottom: 10px;
    .fc-message {
      width: 1100px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .fc-imgContent {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666;
        .img-box {
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 120px;
          padding-bottom: 8px;
          img {
            width: 120px;
            height: 120px;
            margin-bottom: 8px;
          }
        }
      }
      .fc-title {
        font-size: 16px;
        color: #333;
        margin-bottom: 15px;
      }
      .fc-text {
        font-size: 14px;
        color: #666;
        cursor: pointer;
        margin-bottom: 4px;
      }
    }
    .fc-bottom {
      width: 100%;
      height: 30px;
      line-height: 30px;
      background-color: #b2b2b2;
      color: #333;
      .fcb-content {
        width: 1100px;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        color: #333;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .onlineServicc {
    position: fixed;
    right: 0;
    top: 45%;
    z-index: 99;
    cursor: pointer;
    background-color: #17a763;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    padding: 6px;
    width: 20px;
    text-align: center;
    word-break: break-all;
    border-radius: 4px 0 0 4px;
  }
}
</style>